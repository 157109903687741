<template>
  <b-modal id="detail-costumer-model" :title="title" hide-footer>
    <h4 v-if="isLoading" class="text-center">Sedang memuat...</h4>
    <template v-for="(m, index) in costumerModel">
      <div :key="index">
        <detail-group-input :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :need-label="true" v-model="m.value" :id="index" :skipPriceTag="index === 'berat'" />
      </div>
    </template>
  </b-modal>
</template>

<script>
import { setModelValue } from '@/_helpers'
import detailGroupInput from '@/components/DetailGroupInput.vue'
import Costumer from '@/models/Costumer.js'

const costumerModel = new Costumer()

export default {
  components: {
    detailGroupInput
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Detail Data Pelanggan'
    }
  },
  data () {
    return {
      isLoading: false,
      costumerModel: costumerModel.init(),
    }
  },
  methods: {
    openModal (id) {
      this.$bvModal.show('detail-costumer-model')
      this.getDetail(id)
    },
    async getDetail (id) {
      this.isLoading = true
      try {
        const resp = await costumerModel.find(id);
        setModelValue(this.costumerModel, resp);
      }
      catch (e) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(e)
        }
      }

      this.isLoading = false
    }
  }
}
</script>
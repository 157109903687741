<template>
  <b-card class="mb-4" no-header>
    <div class="d-flex" style="justify-content: center; align-items: center;flex-wrap: wrap;">
      <div class="w-100 text-center">
        <p>Arahkan QR Code Ke Kamera</p>
      </div>
      <div class="w-100 text-center pb-3 m-auto">
        <div class="w-100 text-center m-auto" style="max-width: 350px;">
          <label for="">Pilih Kamera</label>
          <b-form-select v-model="selected.cameraId" :options="options.device" class="mb-1" />
        </div>
      </div>
      <div id="reader" style="width: 250px;height: 250px;"></div>
    </div>
  </b-card>
</template>

<script>
import {Html5QrcodeScanner, Html5Qrcode} from "html5-qrcode";
// import { debounce } from '@/_helpers'


export default {
  data () {
    return {
      html5QrCode: null,
      selected: {
        cameraId: null,
      },
      options: {
        device: []
      }
    }
  },
  watch: {
    'selected.cameraId': function (val) {
      // setTimeout(() => {
        this.initScanner(val)
      // }, 500);
      // if (this.options.device && this.options.device.length) {
      //   const cameraId = this.options.device[0].id;
      //   this.initScanner(cameraId)
      // }
    },
    // 'filter.with_trash': function () {
    //   this.doFilter()
    // },
  },
  methods: {
    async initScanner (cameraId) {
      try {
        await this.html5QrCode.stop()
      } catch(e) {
        console.log(e)
      }

      setTimeout(() => {
        this.html5QrCode.start(
          cameraId, 
          {
            fps: 10,    // Optional, frame per seconds for qr code scanning
            qrbox: { width: 250, height: 250 }  // Optional, if you want bounded box UI
          }, (decodedText, decodedResult) => {
            if (decodedText.includes(',')) {
              let basePath = '';
              const decodedTextArray = decodedText.split(',');
              if (decodedTextArray[0] === 'kwitansi-pembelian') {
                basePath = '/sales/'
              }
              else if (decodedTextArray[0] === 'kwitansi-tempahan') {
                basePath = '/crafts/'
              }
              else if (decodedTextArray[0] === 'kwitansi-buyback') {
                basePath = '/buy-backs/'
              }
              this.$router.push(basePath + decodedTextArray[1]);
            }
            // console.log(`Code matched = ${decodedText}`, decodedResult);
          }, (errorMessage) => {
            // this.$store.dispatch('notification/error', errorMessage)
        }).catch((err) => {
          console.log(err)
          this.$store.dispatch('notification/error', 'Gagal mengakses kamera.')
        });
      }, 500)

    }
    // filterTransactionNo() {
    //   this.debounceFilter(this);
    // },
    // debounceFilter: debounce((vm) => {
    //   vm.doFilter()
    // }, 350),
  },
  mounted () {
    this.html5QrCode = new Html5Qrcode(/* element id */ "reader");
    navigator.permissions.query({ name: "camera" }).then((result) => {
      if (result.state !== "granted") {
        this.$store.dispatch('notification/info', 'Izinkan web mengakses kamera.')
      } else {
        // this.$store.dispatch('notification/info', 'Memuat kamera.')
      }
    });

    // setTimeout(() => {
      Html5Qrcode.getCameras().then(devices => {
        this.options.device = []
        devices.forEach(device => {
          this.options.device.push({value: device.id, text: device.label})
        })
        if (devices && devices.length) {
          let selectedDevice = devices[0];
          if (devices.length > 1) {
            const find = devices.find(device => device.label.includes('back'));
            console.log('find', find)
            if (find) {
              selectedDevice = find
            }
          }
          this.selected.cameraId = selectedDevice.id;
          // const cameraId = devices[0].id;
          // // .. use this to start scanning.
          // this.initScanner(cameraId)
        }
      }).catch(err => {
        console.log(err)
        this.$store.dispatch('notification/error', 'Gagal mengakses kamera.')
      });
    // }, 1100);
  },
  beforeDestroy () {
    if (this.html5QrCode) {
      this.html5QrCode.stop()
    }
  }
}
</script>
<template>
  <b-modal id="add-modal-sale" :title="title" hide-footer>
    <ValidationObserver ref="form">
      <form @submit.prevent="loadData">
        <div class="mb-3">
          <b-input class="w-100" placeholder="Cari bedasarkan nama" @keyup="filterWithKeyboard" v-model="filter.nama" @blur="loadData" />
        </div>
        <div>
          <div v-if="data.length" class="list-group">
            <a href="#" @click.prevent="select(stock)" v-for="stock in data" :key="stock.id" class="list-group-item list-group-item-action">
              <b-row>
                <b-col cols="4" v-if="stock.foto && stock.foto !== ''">
                  <img :src="baseUrl + stock.foto" class="img-fluid" alt="">
                </b-col>
                <b-col :cols="stock.foto && stock.foto !== '' ? 8 : 12">
                  <p class="pb-1 m-0">{{ stock.nama }}</p>
                  <small>Berat: {{ stock.berat }}, Kadar: {{ stock.berat }}</small>
                </b-col>
              </b-row>
            </a>
          </div>
          <p v-else class="text-center">Tidak ada stok tersedia</p>

        </div>
        <div style="display: none;">
            <b-button type="submit" variant="primary" class="mt-0"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Simpan</b-button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import Stock from '@/models/Stock.js'
import { debounce } from '@/_helpers'
import formGroupInput from '@/components/FormGroupInput.vue'

const stockModel = new Stock()

export default {
  components: {
    formGroupInput
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Tambah Data Barang'
    },
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      isLoading: false,
      fotoRequired: true,
      data: [],
      filter: {
        nama: null,
      },
      apiUrl: {
        stock: stockModel.endpoint
      },
    }
  },
  methods: {
    filterWithKeyboard() {
      this.debounceFilter(this);
    },
    debounceFilter: debounce((vm) => {
      vm.loadData()
    }, 350),
    setFotoRequired (value) {
      this.fotoRequired = value
    },
    openModal () {
      this.resetForm()
      this.loadData()
      this.$bvModal.show('add-modal-sale')
    },
    hideModal () {
      this.$bvModal.hide('add-modal-sale')	
    },
    loadData () {
      this.isLoading = true
      stockModel.list({
        nama: this.filter.nama ? this.filter.nama : null,
        sisa_stok: 1
      })
        .then(response => {
          this.data = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    resetForm () {
      this.filter.nama = null
    },
    select (row) {
      this.$emit('submit', row)
      this.hideModal()
    }
  }
}
</script>
<template>
  <b-card class="mb-4" no-header>
    <b-row>
      <b-col cols="12">
        <div class="d-flex mb-3">
          <b-button-group class="add-button-group">
            <b-btn variant="primary" to="/stocks/create">Tambah Data</b-btn>
          </b-button-group>
          <div class="ml-auto" v-if="$store.getters['account/accountData'].role === Roles.ADMIN">
            <b-form-checkbox id="checkbox-trash" v-model="filter.with_trash" name="checkbox-trash" :value="1" :unchecked-value="null">
            Lihat Data Terhapus
            </b-form-checkbox>
          </div>
          <!-- <b-button-group class="add-button-group ml-auto">
            <b-btn variant="success" type="button" @click.prevent="doExport"><font-awesome-icon icon="file-excel"/> Ekspor</b-btn>
          </b-button-group> -->
        </div>
      </b-col>
      <b-col cols="12" class="mb-4">
        <b-form @submit.prevent="doFilter">
          <b-row class="filter-row">
            <b-col cols="6" md="4">
              <form-group-input ref="filter-stock_barcode" @keyup="filterWithKeyboard" type="text" placeholder="" :need-label="true" label="barcode" v-model="filter.stock_barcode" id="filter-stock_barcode" />
            </b-col>
            <b-col cols="6" md="4">
              <form-group-input ref="filter-nama" @keyup="filterWithKeyboard" type="text" placeholder="" :need-label="true" label="nama" v-model="filter.nama" id="filter-nama" />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-checkbox id="checkbox-sisa_stok" v-model="filter.sisa_stok" name="checkbox-sisa_stok" :value="1" :unchecked-value="null">
                Hanya Stok Tersedia
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <paginated-table ref="dataTable" :delete-reason="false" :can-delete="true" :can-edit="true" :data-url="apiUrl.stock" :data-columns="columns" :dataParams="dataParams" :defaultDataParams="this.filter">
      <template slot="content_stok" slot-scope="defaultSlotScope">
        <b-badge variant="primary" v-if="defaultSlotScope.colData.sisa_stok">Tersedia</b-badge>
        <b-badge v-else>Terjual</b-badge>
      </template>
      <template slot="content_nama" slot-scope="defaultSlotScope">
        <router-link class="text-dark  text-left" :to="'/stocks/' + defaultSlotScope.colData.id" style="text-decoration: underline;">{{defaultSlotScope.colData.nama}}</router-link><a href="#" copyBarcode class="bg-secondary text-light" style="border-radius: 4px;margin-left: 5px;display: inline-block; padding: 3px 5px;" title="Copy barcode" @click.prevent="copyBarcode(defaultSlotScope.colData.stock_barcode)">
          <font-awesome-icon icon="barcode"/>
        </a>
      </template>
    </paginated-table>
      <input type="text" :id="`copy-barcode-text`" style="z-index: -1122; position: absolute; opacity: 0;">
  </b-card>
</template>

<script>
// import { objectToOptions } from '@/_helpers'
import paginatedTable from '@/components/PaginatedTable.vue'
import formGroupInput from '@/components/FormGroupInput.vue'
import Stock from '@/models/Stock.js'
import { Roles, debounce } from '@/_helpers'

const stockModel = new Stock()

export default {
  components: {
    formGroupInput,
    paginatedTable
  },
  data () {
    return {
      Roles: Roles,
      isLoading: false,
      totalPromiseAreaCount: 0,
      apiUrl: {
        stock: stockModel.endpoint
      },
      columns: [
        {
          prop: 'nama',
          label: 'Nama',
          deletedDetailHere: true,
          sortable: true,
          resizable: true,
          minWidth: 250,
          wrapInLink: true,
        },
        {
          prop: 'berat',
          label: 'Berat (Gram)',
          resizable: true,
          sortable: true,
          minWidth: 90,
          align: 'left',
        },
        {
          prop: 'kadar',
          label: 'Kadar',
          resizable: true,
          sortable: true,
          minWidth: 100,
        },
        {
          prop: 'stok',
          label: 'Stok',
          resizable: true,
          sortable: true,
          minWidth: 80,
          align: 'center',
        },
        {
          prop: 'harga_modal',
          label: 'Harga Modal',
          resizable: true,
          sortable: true,
          needCurrency: true,
          minWidth: 125,
          align: 'right',
        },
        {
          prop: 'harga_jual',
          label: 'Harga Jual',
          resizable: true,
          sortable: true,
          needCurrency: true,
          minWidth: 125,
          align: 'right',
        },
      ],
      filter: {
        with_trash: typeof this.$route.query.with_trash !== 'undefined' ? parseInt(this.$route.query.with_trash) : null,
        nama: typeof this.$route.query.nama !== 'undefined' ? this.$route.query.nama : null,
        sisa_stok: typeof this.$route.query.sisa_stok !== 'undefined' ? this.$route.query.sisa_stok : 1,
        stock_barcode: typeof this.$route.query.stock_barcode !== 'undefined' ? this.$route.query.stock_barcode : null,
        
      },
      dataParams: ['nama', 'with_trash', 'stock_barcode', 'sisa_stok']
    }
  },
  beforeMount () {
    this.$router.push({query: {...this.$route.query}}).catch(err => {})
    // if (!this.$route.query.sisa_stok) {
    //   this.filter.sisa_stok = 1
    // }
  },
  watch: {
    'filter.with_trash': function () {
      this.filter.sisa_stok = this.filter.with_trash ? null : 0
      this.doFilter()
    },
    'filter.sisa_stok': function () {
      this.doFilter()
    }
  },
  methods: {
    copyBarcode (barcodeText) {
      let copyText = document.getElementById("copy-barcode-text");
      copyText.value = barcodeText
      // Select the text field
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.value);
      this.$store.dispatch('notification/success', 'Barcode berhasil di copy.')
    },
    filterWithKeyboard() {
      this.debounceFilter(this);
    },
    debounceFilter: debounce((vm) => {
      vm.doFilter()
    }, 350),
    clearForm () {
      this.$nextTick(() => {
        this.filter = {
          nama: null,
          with_trash: null,
          stock_barcode: null,
        }
        setTimeout(() => {
          this.doFilter()
        }, 100)
      })
    },
    doFilter () {
      this.$refs.dataTable.doFilter(this.filter)
    },
    doExport () {
      if (this.isLoading) {
        return false;
      }
      this.isLoading = true;
      const filterArray = [];
      Object.keys(this.filter).forEach(key => {
        if (this.filter[key]) {
          filterArray.push(key + "=" + this.filter[key]);
        }
      });
      filterArray.push('csv=1');

      this.$http.get(stockModel.getEndpoint() + "?" + filterArray.join("&")).then(response => {
        const a = document.createElement("a");

        const blob = new Blob([response.data], {type: "octet/stream"});
        a.href = window.URL.createObjectURL(blob);

        a.download = "data_admin.csv";
        a.click();
        this.isLoading = false;
      }).catch(error => {
        this.$store.dispatch('notification/error', error)
        this.isLoading = false;
      })
    }
  }
}
</script>
<template>
  <b-card class="mb-4" no-header>
    <b-row>
      <b-col cols="12">
        <div class="d-flex mb-3">
          <b-button-group class="add-button-group">
            <b-btn variant="primary" to="/sales/create">Tambah Data</b-btn>
          </b-button-group>
          <div class="ml-auto" v-if="$store.getters['account/accountData'].role === Roles.ADMIN">
            <b-form-checkbox id="checkbox-trash" v-model="filter.with_trash" name="checkbox-trash" :value="1" :unchecked-value="null">
            Lihat Data Terhapus
            </b-form-checkbox>
          </div>
          <!-- <b-button-group class="add-button-group ml-auto">
            <b-btn variant="success" type="button" @click.prevent="doExport"><font-awesome-icon icon="file-excel"/> Ekspor</b-btn>
          </b-button-group> -->
        </div>
      </b-col>
      <b-col cols="12" class="mb-4">
        <b-form @submit.prevent="doFilter">
          <b-row class="filter-row">
            <b-col cols="12" md="4">
              <form-group-input ref="filter-transaction_no" type="text" placeholder="" :need-label="true" label="no. invoice" v-model="filter.transaction_no" id="filter-transaction_no" @keyup="filterTransactionNo" />
            </b-col>
            <!-- <b-col cols="12" md="2">
              <form-group-input ref="filter-status_bayar" type="enum" placeholder="" :need-label="true" label="status" v-model="filter.status_bayar" id="filter-status_bayar" :options="objectToOptions({
                'belum lunas': 'Belum Lunas',
                'lunas': 'Lunas',
              })" />
            </b-col> -->
            <b-col cols="12" md="3">
              <form-group-input ref="filter-tanggal_transaksi" type="date" placeholder="" :need-label="true" label="tgl transaksi" v-model="filter.tanggal_transaksi" id="filter-tanggal_transaksi" />
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <paginated-table ref="dataTable" :delete-reason="false" :can-delete="true" :can-edit="$store.getters['account/accountData'].role === Roles.ADMIN" :data-url="apiUrl.sale" :data-columns="columns" :dataParams="dataParams" :defaultDataParams="this.filter">
      <template slot="content_costumer" slot-scope="defaultSlotScope">
        {{ defaultSlotScope.colData.costumer.nama }} <br> <small>No HP: {{ defaultSlotScope.colData.costumer.no_hp }}</small>
      </template>
      <template slot="content_status_bayar" slot-scope="defaultSlotScope">
        <b-badge variant="success" v-if="defaultSlotScope.colData.status_bayar === 'lunas'">Lunas</b-badge>
        <b-badge variant="secondary" v-else>Belum Lunas</b-badge>
        <b-button v-if="defaultSlotScope.colData.status_bayar !== 'lunas'" variant="success" size="sm" class="mb-0 py-0 px-1 text-center ml-1" @click.prevent="doLunas(defaultSlotScope.colData.id)">
          <font-awesome-icon icon="check"/>
        </b-button>
      </template>
    </paginated-table>
  </b-card>
</template>

<script>
import { objectToOptions } from '@/_helpers'
import paginatedTable from '@/components/PaginatedTable.vue'
import formGroupInput from '@/components/FormGroupInput.vue'
import Sale from '@/models/Sale.js'
import { Roles, debounce } from '@/_helpers'

const saleModel = new Sale()

export default {
  components: {
    formGroupInput,
    paginatedTable
  },
  data () {
    return {
      Roles: Roles,
      isLoading: false,
      totalPromiseAreaCount: 0,
      apiUrl: {
        sale: saleModel.endpoint
      },
      columns: [
        {
          prop: 'transaction_no',
          label: 'No. Invoice',
          deletedDetailHere: true,
          sortable: true,
          resizable: true,
          minWidth: 110,
          wrapInLink: true,
        },
        {
          prop: 'costumer',
          label: 'Pelanggan',
          resizable: true,
          sortable: true,
          minWidth: 200,
          align: 'left',
        },
        {
          prop: 'tanggal_transaksi',
          label: 'Tanggal',
          align: 'center',
          formatThisDate: true,
          resizable: true,
          sortable: true,
          minWidth: 100,
        },
        // {
        //   prop: 'metode_pembayaran',
        //   label: 
        //   // label: 'Stok',
        //   resizable: true,
        //   sortable: true,
        //   minWidth: 80,
        //   align: 'center',
        // },
        {
          prop: 'total',
          label: 'Total',
          resizable: true,
          sortable: true,
          needCurrency: true,
          minWidth: 100,
          align: 'right',
        },
        // {
        //   prop: 'sisa',
        //   label: 'Sisa',
        //   resizable: true,
        //   sortable: true,
        //   needCurrency: true,
        //   minWidth: 100,
        //   align: 'right',
        // },
        // {
        //   prop: 'status_bayar',
        //   label: 'Status',
        //   resizable: true,
        //   sortable: true,
        //   // needCurrency: true,
        //   minWidth: 100,
        //   align: 'center',
        // },
      ],
      filter: {
        with_trash: typeof this.$route.query.with_trash !== 'undefined' ? parseInt(this.$route.query.with_trash) : null,
        transaction_no: typeof this.$route.query.transaction_no !== 'undefined' ? this.$route.query.transaction_no : null,
        tanggal_transaksi: typeof this.$route.query.tanggal_transaksi !== 'undefined' ? this.$route.query.tanggal_transaksi : null,
        status_bayar: typeof this.$route.query.status_bayar !== 'undefined' ? this.$route.query.status_bayar : null,
        
      },
      dataParams: ['transaction_no', 'with_trash', 'tanggal_transaksi', 'status_bayar']
    }
  },
  beforeMount () {
    this.$router.push({query: {...this.$route.query}}).catch(err => {})
  },
  watch: {
    'filter.with_trash': function () {
      this.doFilter()
    },
    'filter.status_bayar': function () {
      this.doFilter()
    },
    'filter.tanggal_transaksi': function () {
      this.doFilter()
    },
  },
  methods: {
    objectToOptions (obj) {
      return objectToOptions(obj)
    },
    filterTransactionNo() {
      this.debounceFilter(this);
    },
    debounceFilter: debounce((vm) => {
      vm.doFilter()
    }, 350),
    clearForm () {
      this.$nextTick(() => {
        this.filter = {
          transaction_no: null,
          with_trash: null,
          tanggal_transaksi: null,
        }
        setTimeout(() => {
          this.doFilter()
        }, 100)
      })
    },
    doFilter () {
      this.$refs.dataTable.doFilter(this.filter)
    },
    doLunas (id) {
      this.$http.patch(this.apiUrl.sale + '/set-lunas/' + id).then(() => {
        this.$store.dispatch('notification/success', 'Berhasil mengubah status lunas.')
        this.doFilter()
      }).catch(error => {
        this.$store.dispatch('notification/error', 'Gagal mengubah data menjadi lunas.')
      })
    },
    doExport () {
      if (this.isLoading) {
        return false;
      }
      this.isLoading = true;
      const filterArray = [];
      Object.keys(this.filter).forEach(key => {
        if (this.filter[key]) {
          filterArray.push(key + "=" + this.filter[key]);
        }
      });
      filterArray.push('csv=1');

      this.$http.get(saleModel.getEndpoint() + "?" + filterArray.join("&")).then(response => {
        const a = document.createElement("a");

        const blob = new Blob([response.data], {type: "octet/stream"});
        a.href = window.URL.createObjectURL(blob);

        a.download = "data_admin.csv";
        a.click();
        this.isLoading = false;
      }).catch(error => {
        this.$store.dispatch('notification/error', error)
        this.isLoading = false;
      })
    }
  }
}
</script>
import axios from 'axios'
import BaseModel from '@/models/BaseModel.js'

export default class Craft extends BaseModel {
    fields =  {
      'costumer_id': {
        label: 'costumer_id',
        type: 'hidden',
        // rules: 'required'
      },
      'tanggal_transaksi': {
        label: 'Tanggal',
        type: 'date',
        rules: 'required'
      },
      'tanggal_diambil': {
        label: 'Tgl Diambil',
        type: 'date',
        rules: null
      },
      'panjar': {
        label: 'Panjar',
        type: 'price',
        rules: 'required'
      },
      'sisa': {
        label: 'Sisa',
        type: 'price',
        disableSelection: true,
        rules: null
      },
      'metode_pembayaran': {
        label: 'Metode pembayaran',
        type: 'enum',
        value: 'cash',
        options: {
          'cash': 'Cash',
          'transfer': 'Transfer',
          'edc': 'Edc',
          'qris': 'QRIS',
        },
        rules: 'required'
      },
      'status_bayar': {
        label: 'Status',
        type: 'hidden',
        rules: null
      },
      'catatan': {
        label: 'Catatan',
        type: 'textarea',
        rules: null
      },
    }
    endpoint = process.env.VUE_APP_API_URL + 'crafts'

    create (value) {
      return new Promise((resolve, reject) => {
        let formData =this.initForEndpoint(value, true)
        let i = 0;
        Object.keys(value.details).forEach(key => {
          formData.append(`detail_barang[${i}][qty]`, 1)
          Object.keys(value.details[key]).forEach(id => {
            if (typeof value.details[key][id] !== 'undefined' && value.details[key][id] !== null) {
              if (value[key] instanceof File) {
                formData.append(`detail_barang[${i}][${id}]`, value.details[key][id], value.details[key][id].name)
              } else {
                formData.append(`detail_barang[${i}][${id}]`, value.details[key][id])
              }
            }
          })
          i++;
        });
        axios.post(this.endpoint, formData).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
    update (id, value) {
      return new Promise((resolve, reject) => {
        let formData = this.initForEndpoint(value, true)
        let i = 0;
        Object.keys(value.details).forEach(key => {
          if (!value.details[key].id) {
            formData.append(`detail_barang[${i}][qty]`, 1)
            Object.keys(value.details[key]).forEach(id => {
              if (typeof value.details[key][id] !== 'undefined' && value.details[key][id] !== null) {
                if (value[key] instanceof File) {
                  formData.append(`detail_barang[${i}][${id}]`, value.details[key][id], value.details[key][id].name)
                } else {
                  formData.append(`detail_barang[${i}][${id}]`, value.details[key][id])
                }
              }
            })
            i++;
          }
        });

        i = 0
        value.deleted_ids.forEach(deleted_id => {
          formData.append(`deleted_ids[${i}]`, deleted_id)
          i++;
        });

        formData.append('_method', 'PATCH')
        axios.post(this.endpoint + '/' + id, formData).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
}
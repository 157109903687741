<template>
  <b-card class="mb-4" no-header>
    <b-row>
      <b-col cols="12">
        <div class="d-flex mb-3">
          <b-button-group class="add-button-group">
            <b-btn variant="primary" to="/users/create">Tambah Data</b-btn>
          </b-button-group>
          <!-- <b-button-group class="add-button-group ml-auto">
            <b-btn variant="success" type="button" @click.prevent="doExport"><font-awesome-icon icon="file-excel"/> Ekspor</b-btn>
          </b-button-group> -->
        </div>
      </b-col>
      <b-col cols="12" class="mb-4">
        <b-form @submit.prevent="doFilter">
          <b-row class="filter-row">
            <b-col cols="6" md="4">
              <form-group-input ref="filter-name" type="text" placeholder="" :need-label="true" label="nama" v-model="filter.name" id="filter-name" />
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <paginated-table ref="dataTable" :delete-reason="false" :can-delete="true" :can-edit="true" :data-url="apiUrl.user" :data-columns="columns" :dataParams="dataParams" :defaultDataParams="this.filter">
    </paginated-table>
  </b-card>
</template>

<script>
import { objectToOptions } from '@/_helpers'
// import { objectToOptions, getYears, formatModelValue } from '@/_helpers'
import paginatedTable from '@/components/PaginatedTable.vue'
import formGroupInput from '@/components/FormGroupInput.vue'
import User from '@/models/User.js'

const userModel = new User()

export default {
  components: {
    formGroupInput,
    paginatedTable
  },
  data () {
    return {
      isLoading: false,
      totalPromiseAreaCount: 0,
      apiUrl: {
        user: userModel.endpoint
      },
      columns: [
        {
          prop: 'name',
          label: 'Nama',
          sortable: true,
          resizable: true,
          minWidth: 200,
          wrapInLink: true,
        },
        {
          prop: 'email',
          label: 'Email',
          resizable: true,
          sortable: true,
          minWidth: 150
        },
        {
          prop: 'role',
          label: 'Peran',
          resizable: true,
          sortable: true,
          minWidth: 125
        },
      ],
      filter: {
        name: typeof this.$route.query.name !== 'undefined' ? this.$route.query.name : null,
      },
      dataParams: ['name']
    }
  },
  beforeMount () {
    this.$router.push({query: {...this.$route.query}}).catch(err => {})
  },
  methods: {
    clearForm () {
      this.$nextTick(() => {
        this.filter = {
          name: null,
        }
        setTimeout(() => {
          this.doFilter()
        }, 100)
      })
    },
    doFilter () {
      this.$refs.dataTable.doFilter(this.filter)
    },
    doExport () {
      if (this.isLoading) {
        return false;
      }
      this.isLoading = true;
      const filterArray = [];
      Object.keys(this.filter).forEach(key => {
        if (this.filter[key]) {
          filterArray.push(key + "=" + this.filter[key]);
        }
      });
      filterArray.push('csv=1');

      this.$http.get(userModel.getEndpoint() + "?" + filterArray.join("&")).then(response => {
        const a = document.createElement("a");

        const blob = new Blob([response.data], {type: "octet/stream"});
        a.href = window.URL.createObjectURL(blob);

        a.download = "data_admin.csv";
        a.click();
        this.isLoading = false;
      }).catch(error => {
        this.$store.dispatch('notification/error', error)
        this.isLoading = false;
      })
    }
  }
}
</script>
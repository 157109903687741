<template>
  <b-modal id="image-model" :title="title" hide-footer>
    <div v-if="image" class="m-auto text-center">
      <img :src="image" class="img-fluid" alt="">
    </div>
  </b-modal>
</template>

<script>

export default {
  // props: {
  //   title: {
  //     type: String,
  //     required: true
  //   }
  // },
  data () {
    return {
      title: null,
      image: null,
      isLoading: false,
    }
  },
  methods: {
    setTitle (val) {
      this.title = val
    },
    setImage (file) {
      if (typeof file === 'string') {
        this.image = process.env.VUE_APP_API_BASE_URL + file
      } else {
        this.image = null;
        const reader = new FileReader();
        reader.onloadend = () => {
          this.image = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },
    openModal () {
      this.$bvModal.show('image-model')
    },
    hideModal () {
      this.$bvModal.hide('image-model')	
    },
  }
}
</script>
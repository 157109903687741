import axios from 'axios'
import BaseModel from '@/models/BaseModel.js'
import { Roles } from '@/_helpers'

const roles = {};
roles[Roles.ADMIN] = 'Admin';
roles[Roles.STAFF] = 'Staff';

export default class User extends BaseModel {
    fields =  {
      'name': {
        label: 'Nama',
        type: 'text',
        rules: 'required'
      },
      'email': {
        type: 'email',
        rules: 'required'
      },
      'password': {
        type: 'password',
        rules: 'required|min:6'
      },
      'role': {
        label: 'Hak Akses',
        type: 'enum',
        options: roles,
        rules: 'required'
      },
    }
    endpoint = process.env.VUE_APP_API_URL + 'users'

    updateProfile (value) {
      return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_API_URL + 'setting', this.initForEndpoint(value)).then(response => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
}
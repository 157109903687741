<template>
    <div>
    <back-button default-to="/costumers"/>
      <b-card no-header no-footer>
        <ValidationObserver ref="form">
          <form @submit.prevent="onSubmit">
              <b-row>
                <b-col cols="12" md="6">
                  <template v-for="(m, index) in costumerModel">
                    <div :key="index">
                      <form-group-input :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
                    </div>
                    
                  </template>
                </b-col>
              </b-row>
              <div>
                <b-button type="submit" variant="primary" class="mt-0"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Simpan</b-button>
                <b-button :to="'/costumers'" variant="secondary" class="ml-3 float-right"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Batal</b-button>
              </div>
            </form>
        </ValidationObserver>
    </b-card>
  </div>
</template>

<script>
import { saveProcess, setModelValue, scrollToError, objectToOptions, classicObjectToOptions } from '@/_helpers'
import formGroupInput from '@/components/FormGroupInput.vue'
import Costumer from '@/models/Costumer.js'

const costumerModel = new Costumer()

export default {
  components: {
    formGroupInput
  },
  watch: {
    '$route.params.id': function (val) {
      if (this.mode === 'update') {
        this.getDetail()
      }
    },
  },
  computed: {
    mode: function () {
      return this.$route.params.id !== undefined ? 'update' : 'create'
    }
  },
  data () {
    return {
      initEdit: false,
      isLoading: false,
      costumerModel: costumerModel.init()
    }
  },
  mounted () {
    if (this.mode === 'update') {
      this.getDetail()
    }
  },
  methods: {
    getOptions (key) {
      return typeof this.costumerModel[key].options !== 'undefined' ? objectToOptions(this.costumerModel[key].options) : null
    },
    getClassicOptions (key) {
      return typeof this.costumerModel[key].options !== 'undefined' ? classicObjectToOptions(this.costumerModel[key].options) : null
    },
    getDetail () {
      this.initEdit = true
      this.isLoading = true
      costumerModel.find(this.$route.params.id).then(resp => {
        this.isLoading = false
        setModelValue(this.costumerModel, resp)
        Object.keys(this.costumerModel).forEach(key => {
          if (typeof this.$refs[key] !== 'undefined') {
            this.$refs[key][0].setValue(this.costumerModel[key].value)
          }
        })
      }).catch(error => {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error)
        }
        // this.initEdit = false
        this.isLoading = false
      })
    },
    resetForm () {
      this.$nextTick(() => {
        // clearModelValue(this.costumerModel)
        Object.keys(this.costumerModel).forEach(key => {
          this.$refs[key][0].setValue(null)
        })
        this.$refs.form.reset()
      })
    },
    onSubmit () {
      this.$refs.form.validate().then(success => {
        if (!success || this.isLoading) {
          scrollToError(this.$refs.form)
          return false
        }
        saveProcess(this.costumerModel, this.mode === 'update').then(model => {
          this.isLoading = true
          if (this.mode === 'update') {
            costumerModel.update(this.$route.params.id, model).then(() => {
              this.isLoading = false
              this.$store.dispatch('notification/success', 'Data costumer berhasil diubah.')
              this.$router.push('/costumers/' + this.$route.params.id)
            }).catch(error => {
              this.isLoading = false
              this.$store.dispatch('notification/error', error)
            })
          } else {
            costumerModel.create(model).then(resp => {
              this.isLoading = false
              // this.resetForm()
              this.$store.dispatch('notification/success', 'Data costumer berhasil disimpan.')
              this.$router.push('/costumers')
              // this.$router.push('/costumers/' + resp.id)
            }).catch(error => {
              this.isLoading = false
              this.$store.dispatch('notification/error', error)
            })
          }
        }).catch(() => {})
      })
    }
  }
}
</script>
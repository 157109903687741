<template>
    <div class="mb-3" v-bind:class="{ 'd-none':  $attrs.type === 'hidden'}">
      <label class="form-label w-100" style="font-size: 80%;">{{ getLabel(typeof label === 'string' && label !== null && label.trim() !== '' ? label : $attrs.id) }}</label>
      <template v-if="value === null">
        <p class="heading-color">-</p>
      </template>
      <template v-else-if="$attrs.type !== 'hidden'">
        <slot v-if="hasSlot('content_' + $attrs.id)" :name="'content_' + $attrs.id" :col-data="value"/>
        <p v-else-if="$attrs.type === 'date'" class="heading-color">{{$moment(value).format('DD-MM-YYYY')}}</p>
        <p v-else-if="$attrs.type === 'image' && value" class="heading-color">
          <a :href="baseUrl + value" target="_blank"><img :src="baseUrl + value" class="img-fluid" style="max-height: 150px;" alt=""></a>
        </p>
        <p v-else class="heading-color">{{!skipPriceTag && $attrs.type === 'price' ? 'Rp' : ''}}{{value}}</p>
      </template>
    </div>
</template>
<script>
  // import { formatIdrInput } from '@/_helpers'

  export default {
    inheritAttrs: false,
    name: 'dg-input',
    props: {
      value: [String, Number, Object],
      needLabel: {
        type: Boolean,
        required: false,
        default: function () {
            return false
        }
      },
      skipPriceTag: {
        type: Boolean,
        required: false,
        default: function () {
          return false
        }
      },
      label: {
        type: String,
        required: false,
        default: function () {
          return null
        }
      }
    },
    // watch: {
    //   'value': function (val) {
    //     console.log(val)
    //   }
    // },
    // mounted () {
    //   console.log(this.value)
    // },
    data () {
      return {
        baseUrl: process.env.VUE_APP_API_BASE_URL
      }
    },
    methods: {
      hasSlot (name = 'default') {
        // if (typeof this.$slots[name] === 'undefined') {
        //   return false
        // }
        return !!this.$slots[name] || !!this.$scopedSlots[name]
      },
      getLabel (value) {
        return value !== null ? value.replace(/_/g, ' ') : null
      },
      setValue (value) {
        if (this.$attrs.type === 'date') {
          this.datepicker = value !== null ? new Date(value) : null
        } 
        this.$nextTick(() => {
          this.$emit('input', value)
        })
      }
    }
  }
</script>
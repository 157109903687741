<template>
    <div>
    <back-button default-to="/crafts"/>
      <b-card no-header no-footer>
        <ValidationObserver ref="form">
          <form @submit.prevent="onSubmit">
              <b-row>
                <b-col cols="12" md="6" class="mb-1">
                  <div>
                    <div>
                      <label for="nama" class="form-label mb-2">
                        Pelanggan <span class="text-danger">*</span> <a v-if="costumer.id" href="#" @click.prevent="resetCostumer" class="text-light p-1 px-2 bg-secondary" style="border-radius: 3px;"><font-awesome-icon icon="sync-alt"/> </a> <a v-else href="#" @click.prevent="openCostumerModal" class="text-light p-1 px-2 bg-dark" style="border-radius: 3px;"><font-awesome-icon icon="plus"/> </a>
                      </label>
                    </div>
                    <span v-if="costumer.id" style="cursor: pointer;" @click.prevent="$refs.costumerDetailModal.openModal(costumer.id)">
                      <div class="d-flex flex-wrap">
                        <div class="mb-2 mr-2"><strong>Nama</strong>: {{ costumer.nama}}</div><div class="mb-2 mr-2">, <strong>No. HP</strong>: {{ costumer.no_hp }}</div><div class="mb-2" v-if="costumer.nik">, <strong>NIK</strong>: {{ costumer.nik }}</div>
                      </div>
                    </span>
                    <div v-else>
                      <b-row class="mb-2">
                        <b-col cols="12" md="12">
                          <v-select 
                            class="w-100"
                            placeholder="cari no hp, nik atau nama costumer"
                            v-model="costumerSelectModel"
                            label="name"
                            :filterable="false"
                            :options="options.costumers"
                            @search="fetchCostumers">
                              <template slot="no-options">
                                ketikkan no hp, nik atau nama
                              </template>
                              <template slot="option" slot-scope="option">
                                <div class="d-block" style="font-size: 90%;">
                                  <div class="mb-0">
                                    <strong>Nama</strong>: {{ option.nama }}
                                  </div>
                                    <div class="mb-0"><strong>No. HP</strong>: {{ option.no_hp }}</div>
                                  <div class="mb-0" v-if="option.nik"><strong>NIK</strong>: {{ option.nik }}</div>
                                </div>
                              </template>
                              <template slot="selected-option" slot-scope="option">
                                <div class="d-block" style="font-size: 95%;">
                                  <div class="mb-0">
                                    <strong>Nama</strong>: {{ option.nama }}
                                  </div>
                                    <div class="mb-0"><strong>No. HP</strong>: {{ option.no_hp }}</div>
                                  <div class="mb-0" v-if="option.nik"><strong>NIK</strong>: {{ option.nik }}</div>
                                </div>
                              </template>
                              <!-- <template #search="{attributes, events}">
                                <input
                                  class="vs__search"
                                  :state="rules !== null ? (errors.length ? valid : null) : null"
                                />
                              </template> -->
                          </v-select>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>
                <b-col v-for="(m, index) in filterCraftModel(['tanggal_transaksi', 'tanggal_diambil'])" cols="12" md="3" :key="'craft_model_1_ ' + index">
                  <div>
                    <label class="form-label mb-2">{{ m.label }} <span class="text-danger" v-if="index === 'tanggal_transaksi'">*</span></label> 
                    <form-group-input :needLabel="false" :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
                  </div>
                </b-col>
              </b-row>
              <div class="mt-2 mb-3">
                <form-detail ref="formDetail" :model="craftDetailModel" :rows="craftDetails" colTotlalName="harga" />
              </div>
              <div class="mt-2 mb-3">
                <b-row>
                  <b-col cols="12" md="4" v-for="(m, index) in filterCraftModel(['panjar', 'sisa', 'metode_pembayaran'])" :key="'craft_model_2_ ' + index">
                    <form-group-input :needLabel="true" :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" :disableSelection="m.disableSelection" />
                  </b-col>
                </b-row>
              </div>
              <div class="mt-3 mb-3">
                <template v-for="(m, index) in filterCraftModel(['catatan'])">
                  <div :key="'catatan_model_1_ ' + index">
                    <form-group-input :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
                  </div>
                </template>
              </div>
              <div>
                <b-button type="submit" variant="primary" class="mt-0"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Simpan</b-button>
                <b-button :to="'/crafts'" variant="secondary" class="ml-3 float-right"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Batal</b-button>
              </div>
            </form>
        </ValidationObserver>
    </b-card>
    <costumer-add-modal ref="costumerAddModal" :nikRequired="filter.tidak_memiliki_surat == 1" @submit="setCostumer" title="Data Penjual Baru"/>
    <costumer-detail-modal ref="costumerDetailModal" @submit="setCostumer" title="Detail Data Penjual"/>
  </div>
</template>

<script>
import { saveProcess, setModelValue, scrollToError, objectToOptions, classicObjectToOptions, debounce, cleanIdrFormat, formatModelValue } from '@/_helpers'
import formGroupInput from '@/components/FormGroupInput.vue'
import costumerAddModal from '@/pages/Costumer/AddModal.vue'
import costumerDetailModal from '@/pages/Costumer/DetailModal.vue'
import formDetail from '@/components/FormDetail';
import Costumer from '@/models/Costumer.js'
import Craft from '@/models/Craft.js'
import CraftDetail from '@/models/CraftDetail.js'

const craftModel = new Craft()
const craftDetailModel = new CraftDetail()
const costumerModel = new Costumer()

export default {
  components: {
    costumerAddModal,
    costumerDetailModal,
    formGroupInput,
    formDetail
  },
  watch: {
    // 'filter.tidak_memiliki_surat': function (val) {
    //   this.$refs.formDetail.setFotoRequired(val != 1)
    // },
    'costumerSelectModel': function (costumer) {
      if (costumer) {
        this.setCostumer({
          id: costumer.id,
          nama:  costumer.nama,
          no_hp: costumer.no_hp,
          nik: costumer.nik,
        })
      }
    },
    'craftDetails': function () {
      this.countSisa()
    },
    'craftModel.panjar.value': function () {
      this.countSisa()
    },
    '$route.params.id': function (val) {
      if (this.mode === 'update') {
        this.getDetail()
      }
    },
  },
  computed: {
    mode: function () {
      return this.$route.params.id !== undefined ? 'update' : 'create'
    }
  },
  data () {
    return {
      costumerSelectModel: null,
      initEdit: false,
      isLoading: false,
      filter: {
        tidak_memiliki_surat: 0,
      },
      options: {
        costumers: []
      },
      costumer: {
        id: null,
        nama: null,
        no_hp: null,
        nik: null,
      },
      craftModel: craftModel.init(),
      craftDetails: [],
      craftDetailModel: craftDetailModel.init()
    }
  },
  mounted () {
    if (this.mode === 'update') {
      this.getDetail()
    } else {
      setTimeout(() => {
        this.craftModel.tanggal_transaksi.value = this.$moment().format('YYYY-MM-DD')
        this.$refs['tanggal_transaksi'][0].setValue(this.craftModel.tanggal_transaksi.value)
      }, 500);
    }
    setTimeout(() => {
      this.$refs.formDetail.setFotoRequired(false)
    }, 300);
    // this.openCostumerModal()
    // this.setCostumer({
    //   id: 3,
    //   nama: 'Fairuz Ahmad Daulay',
    //   no_hp: '082362216649'
    // })
  },
  methods: {
    countSisa () {
      const panjar = cleanIdrFormat(this.craftModel.panjar.value)
      let total = 0
      this.craftDetails.forEach(detail => {
        total += parseInt(detail.harga)
      })
      let sisa = 0
      if (panjar > 0 && total > 0) {
        sisa = total - panjar
      } else {
        sisa = null
      }
      this.craftModel.sisa.value = formatModelValue(this.craftModel.sisa.type, sisa)
      this.$refs['sisa'][0].setValue(this.craftModel.sisa.value)
    },
    filterCraftModel (search, type = 'include') {
      const filtered = Object.entries(this.craftModel).filter(mdl => {
        return type === 'include' ? search.includes(mdl[0]) : !search.includes(mdl[0])
      });
      let result = {}
      filtered.forEach(element => {
        result[element[0]] = element[1];
      });
      return result;
    },
    fetchCostumers(){
      let timer;
      return () => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
      };
    },
    fetchCostumers(search, loading) {
      if(search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: debounce(async (loading, search, vm) => {
      try {
        const resp = await costumerModel.findByQuery(search);
        vm.options.costumers = resp.data
      } catch (e) {
        console.log(e)
      }
      loading(false);
    }, 350),
    openCostumerModal () {
      this.$refs.costumerAddModal.openModal()
    },
    resetCostumer () {
      this.costumer = {
        id: null,
        nama: null,
        no_hp: null,
        nik: null,
      };
      this.craftModel.costumer_id.value = null;
    },
    setCostumer (costumer) {
      this.costumer = {
        id: costumer.id,
        nama: costumer.nama,
        no_hp: costumer.no_hp,
        nik: costumer.nik ? costumer.nik : null,
      };
      this.craftModel.costumer_id.value = costumer.id;
    },
    getOptions (key) {
      return typeof this.craftModel[key].options !== 'undefined' ? objectToOptions(this.craftModel[key].options) : null
    },
    getClassicOptions (key) {
      return typeof this.craftModel[key].options !== 'undefined' ? classicObjectToOptions(this.craftModel[key].options) : null
    },
    async getDetail () {
      this.isLoading = true
      try {
        const resp = await craftModel.find(this.$route.params.id);
        setModelValue(this.craftModel, resp, true)
        Object.keys(this.craftModel).forEach(key => {
          if (typeof this.$refs[key] !== 'undefined') {
            this.$refs[key][0].setValue(this.craftModel[key].value)
          }
        })
        this.craftDetails = resp.detail_barang;
        if (resp.detail_barang.filter(detail => {
          return detail.foto !== null && detail.foto !== ''
        }).length === 0) {
          this.filter.tidak_memiliki_surat = true
        }

        this.setCostumer({
          id: resp.costumer.id,
          nama: resp.costumer.nama,
          no_hp: resp.costumer.no_hp,
          nik: resp.costumer.nik,
        })
      }
      catch (e) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(e)
        }
      }
      this.isLoading = false
    },
    resetForm () {
      this.$nextTick(() => {
        // clearModelValue(this.craftModel)
        Object.keys(this.craftModel).forEach(key => {
          this.$refs[key][0].setValue(null)
        })
        this.$refs.form.reset()
      })
    },
    onSubmit () {
      if (!this.costumer.id) {
        this.$store.dispatch('notification/error', 'Data penjual wajib diisi')
      } else if (!this.craftDetails.length) {
        this.$store.dispatch('notification/error', 'Data barang wajib diisi')
      } else {
        this.$refs.form.validate().then(success => {
        if (!success || this.isLoading) {
          scrollToError(this.$refs.form)
          return false
        }
        saveProcess(this.craftModel, this.mode === 'update').then(model => {
          this.isLoading = true
          model.details = this.craftDetails
          if (this.mode === 'update') {
            model.deleted_ids = this.$refs.formDetail.getDeletedIds()
            craftModel.update(this.$route.params.id, model).then(() => {
              this.isLoading = false
              this.$store.dispatch('notification/success', 'Data craft berhasil diubah.')
              this.$router.push('/crafts/' + this.$route.params.id)
            }).catch(error => {
              this.isLoading = false
              this.$store.dispatch('notification/error', error)
            })
          } else {
            craftModel.create(model).then(resp => {
              this.isLoading = false
              // this.resetForm()
              this.$store.dispatch('notification/success', 'Data craft berhasil disimpan.')
              this.$router.push('/crafts')
              // this.$router.push('/crafts/' + resp.id)
            }).catch(error => {
              this.isLoading = false
              this.$store.dispatch('notification/error', error)
            })
          }
        }).catch(() => {})
      })
    
      }
    }
  }
}
</script>
<template>
    <div>
        <div class="d-flex mb-3" style="align-items: middle;">
            <h5 class="mb-0">
                Detail Barang
            </h5>
            <span v-if="mode === 'edit' && !useBarcode">
                <a href="#" class="text-light p-2 ml-2 px-2 bg-dark align-middle" @click.prevent="addNewRow()" style="border-radius: 3px;font-size: 0.7rem;display: inline-flex;align-items: center;"><font-awesome-icon icon="plus"/> </a>
            </span>
        </div>
        <b-row v-if="useBarcode">
          <b-col cols="8" md="4" class="mb-2">
            <input type="text" class="form-control" placeholder="Input barcode" id="scan_stock_barcode" v-model="filter.stock_barcode" @keyup="filterWithKeyboard" @blur="scanBarcode">
          </b-col>
          <b-col cols="4" md="8" class="mb-2 text-right">
            <a href="#" class="text-light p-2 ml-2 px-2 bg-dark align-middle" @click.prevent="addNewRowTosales()" style="border-radius: 3px;font-size: 0.7rem;display: inline-flex;align-items: center;"><font-awesome-icon icon="plus"/> </a>
          </b-col>
        </b-row>
        <div style="border-top: 1px solid #dee2e6;height: 1px;"></div>
        <div class="b-table-sticky-header table-responsive"> 
        <table class="table b-table b-table-fixed table-striped table-hover" :class="{ 'product-table-view' : (mode != 'edit' || formType !== 'add-sale') }">
            <thead role="rowgroup" class="table-light">
                <tr role="row" class="table-light">
                  <template v-for="(m, index) in Object.keys(model)">
                    <th :key="'table_header_' + index" v-if="model[m].type !== 'hidden'" role="columnheader" scope="col" :class="'align-middle table-b-table-default ' + getAlign(m)" style="text-transform: capitalize;" :width="model[m].editable ? 137 : getWidth(m)">
                        {{ getLabel(model[m].label ? model[m].label : m) }}
                    </th>
                  </template>

                  <th role="columnheader" v-if="mode === 'edit'" scope="col" width="55" class="align-middle table-b-table-default">&nbsp;</th>
                </tr>
            </thead>
            <tbody role="rowgroup">
                <tr role="row" v-for="(row, index) in rows" :key="'table_col_' + index">
                  <template v-for="(m, index_col) in Object.keys(model)">
                    <td :key="'table_col_value_' + index_col" v-if="model[m].type !== 'hidden'" role="cell" :class="'align-middle ' + getAlign(m)">
                      <span v-if="mode === 'edit' && model[m].editable" style="margin-bottom: -1rem;" class="d-block">
                        <form-group-input :needLabel="false" :type="model[m].type" :label="m" placeholder="" :options="null" v-model="row[m]" :rules="typeof model[m].rules !== 'undefined' ? model[m].rules : null" :disabled="typeof model[m].disabled !== 'undefined' ? model[m].disabled : null" :id="'row_editable_'+ m" />
                      </span>
                      <span v-else-if="row[m] === null || row[m] === ''">-</span>
                      <p v-else-if="model[m].type === 'date'">{{$moment(row[m]).format('DD-MM-YYYY')}}</p>
                      <b-button v-else-if="model[m].type === 'image' && row[m]" @click.prevent="openRowImage(row, row[m])" variant="secondary" size="sm" type="button" class="mb-0 text-center delete">
                        <font-awesome-icon icon="search-plus"/>
                      </b-button>
                      <span v-else-if="model[m].type === 'price'">{{!model[m].skipPriceTag ? 'Rp' : ''}}{{$formatPrice(row[m])}}</span>
                      <span v-else>{{row[m]}}</span>
                    </td>
                  </template>
                    <td role="cell" v-if="mode === 'edit'" class="align-middle">
                        <b-button @click.prevent="deleteRow(index)" variant="danger" size="sm" type="button" class="mb-0 text-center delete">
                            <font-awesome-icon icon="trash"/>
                        </b-button>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="d-flex">
            <span>
                <strong>TOTAL</strong>
            </span>
            <span class="ml-auto">
                <strong>Rp{{ $formatPrice(getTotal) }}</strong>
            </span>
        </div>
        <addModal ref="addModal" :model="model" :fotoRequired="fotoRequired" @submit="submitNewRow"/>
        <addModalSale ref="addModalSales" @submit="addNewRowForSale"/>
        <imageModal ref="imageModal"/>
    </div>
</template>

<script>
import addModal from './AddModal.vue'
import addModalSale from './AddModalSale.vue'
import formGroupInput from '@/components/FormGroupInput.vue'
import imageModal from '@/components/ImageModal.vue'
// import Compressor from 'compressorjs'
import { debounce, cleanIdrFormat } from '@/_helpers'
import Stock from '@/models/Stock'

const stockModel = new Stock()

export default {
    components: {
      addModal,
      addModalSale,
      imageModal,
      formGroupInput
    },
    props: {
      useBarcode: {
        type: Boolean,
        default: false
      },
      mode: {
        type: String,
        default: 'edit'
      },
      model: {
        required: true
      }, 
      rows: {
        required: true
      },
      colTotlalName: {
        required: true
      },
      formType: {
        type: String,
        default: 'add'
      },
    },
    computed: {
        getTotal () {
          let total = 0
          this.rows.forEach(row => {
              Object.keys(this.model).forEach(id => {
                if (id === this.colTotlalName) {
                  let value = row[id] ? row[id] : 0;
                  if (this.model[id].editable) {
                    value = cleanIdrFormat(value)
                  }
                  total += parseInt(value)
                }
              });
          });
          return total
        }
    },
    data () {
      return {
        fotoRequired: true,
        deleted_ids: [],
        loading: {
          scanningBarcode: false
        },
        filter: {
          stock_barcode: null
        }
        // rows: [],
        // costumerSelectModel: null,
        // initEdit: false,
        // isLoading: false,
        // filter: {
        //     tidak_memiliki_surat: 0,
        // },
        // options: {
        //     costumers: []
        // },
        // costumer: {
        //     id: null,
        //     nama: null,
        //     no_hp: null,
        //     nik: null,
        // },
        // buyBackModel: buyBackModel.init(),
        // buyBackDetailModel: buyBackDetailModel.init()
      }
    },
    methods: {
      filterWithKeyboard() {
        this.debounceFilter(this);
      },
      addNewRowForSale (resp) {
        if (this.rows.find(row => row.stock_id === resp.id)) {
          return ;
        }
        resp.stock_id = resp.id;
        resp.harga_jual = (resp.harga_jual && resp.harga_jual > 0 ? this.$formatPrice(resp.harga_jual) : null);
        delete resp.id;

        const row = {};
        Object.keys(this.model).forEach(m => {
          row[m] = resp[m] ? resp[m] : null
        })
        this.submitNewRow(row)
      },
      debounceFilter: debounce((vm) => {
        vm.scanBarcode()
      }, 350),
      async scanBarcode () {
        if (this.filter.stock_barcode && !this.loading.scanningBarcode) {
          if (!this.rows.find(row => row.stock_barcode === this.filter.stock_barcode)) {
            this.loading.scanningBarcode = true
            stockModel.findByBarcode({
              stock_barcode: this.filter.stock_barcode
            }).then(resp => {
              this.loading.scanningBarcode = false
              if (!resp) {
                this.$store.dispatch('notification/error', 'Stok tidak ditemukan')
              } else if (!resp.sisa_stok) {
                this.$store.dispatch('notification/error', 'Stok tidak tersedia')
              } else {
                this.addNewRowForSale(resp)
              }
              document.querySelector('#scan_stock_barcode').focus()
            }).catch(e => {
              console.log(e)
              this.loading.scanningBarcode = false
              document.querySelector('#scan_stock_barcode').focus()
              this.$store.dispatch('notification/error', 'Gagal mengambil data stock, silahkan coba kembali')
            })
          }
        }
        this.filter.stock_barcode = null
      },
      setFotoRequired (value) {
        this.fotoRequired = value
        // this.$refs.addModal.setFotoRequired(value)
      },
      getLabel (value) {
        return value !== null ? value.replace(/_/g, ' ') : null
      },
      getWidth (key) {
        if (key == 'berat') {
            return 130;
        } else if (key == 'kadar') {
            return 180;
        } else if (key == 'berat') {
            return 130;
        } else if (key.includes('harga')) {
            return 120;
        } else if (key.includes('foto')) {
            return 120;
        }
        return null;
      },
      getAlign (key) {
        if (key.includes('nama')) {
            return 'col-header-product-name';
        } else if (key.includes('harga')) {
            return 'text-right';
        } else if (key.includes('foto')) {
            return 'text-center';
        }
        return '';
      },
      addNewRow () {
        if (this.fotoRequired) {
            if (this.rows.length) {
              this.$refs.addModal.setFotoRequired(false)
            } else {
                this.$refs.addModal.setFotoRequired(true)
            }
        } else {
            this.$refs.addModal.setFotoRequired(false)
        }
        this.$refs.addModal.openModal()
      },
      addNewRowTosales () {
        this.$refs.addModalSales.openModal()
      },
      // setRows (rows) {
      //   this.rows = rows
      // },
      getDeletedIds () {
        return this.deleted_ids
      },
      deleteRow (index) {
        if (this.rows[index].id) {
          this.deleted_ids.push(this.rows[index].id)
        }
        this.rows.splice(index, 1);
      },
      submitNewRow (row) {
        this.rows.push(row)
        // setTimeout(() => {
        //   const r = this.rows[this.rows.length - 1]
        //   Object.keys(r).forEach(m => {
        //     if (this.model[m] && this.model[m].type === 'image' && r[m] instanceof File) {
        //       new Compressor(r[m], {
        //         quality: 0.75,
        //         success(result) {
        //             r[m] = result;
        //         },
        //         error(err) {
        //             console.log(err.message);
        //         },
        //       });
        //     }
        //   });
        // }, 100)
      },
      openRowImage (row, file) {
        this.$refs.imageModal.setTitle(row.nama)
        this.$refs.imageModal.setImage(file)
        this.$refs.imageModal.openModal()
      }
    },
    mounted () {
      if (this.useBarcode) {
        document.querySelector('#scan_stock_barcode').focus()
      }
    }
}
</script>
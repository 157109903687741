<template>
  <b-modal id="costumer-model" :title="title" hide-footer>
    <ValidationObserver ref="form">
      <form @submit.prevent="onSubmit">
        <template v-for="(m, index) in costumerModel">
          <div :key="index">
            <form-group-input :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
          </div>
        </template>
        <div>
            <b-button type="submit" variant="primary" class="mt-0"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Simpan</b-button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { saveProcess, setModelValue, objectToOptions, classicObjectToOptions } from '@/_helpers'
import formGroupInput from '@/components/FormGroupInput.vue'
import Costumer from '@/models/Costumer.js'

const costumerModel = new Costumer()

export default {
  components: {
    formGroupInput
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Tambah Data Pelanggan'
    },
    nikRequired: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      costumerModel: costumerModel.init(),
    }
  },
  methods: {
    openModal () {
      this.$bvModal.show('costumer-model')
      setTimeout(() => {
        if (this.$refs['gender']) {
            this.costumerModel.gender.value = 'wanita'
            this.$refs['gender'][0].setValue('wanita')
        }
        if (this.nikRequired) {
          this.costumerModel.nik.rules = 'required';
        }  else {
          this.costumerModel.nik.rules = null
        }
      }, 700);
    },
    hideModal () {
      this.$bvModal.hide('costumer-model')	
    },
    getOptions (key) {
      return typeof this.costumerModel[key].options !== 'undefined' ? objectToOptions(this.costumerModel[key].options) : null
    },
    getClassicOptions (key) {
      return typeof this.costumerModel[key].options !== 'undefined' ? classicObjectToOptions(this.costumerModel[key].options) : null
    },
    resetForm () {
        setModelValue(this.costumerModel, {}, true)
        Object.keys(this.costumerModel).forEach(key => {
          if (typeof this.$refs[key] !== 'undefined' && key !== 'gender') {
            this.$refs[key][0].setValue(null)
          }
        })
    },
    onSubmit () {
      this.$refs.form.validate().then(success => {
        if (!success || this.isLoading) {
          return false
        }
        saveProcess(this.costumerModel, false, true).then(model => {
          this.isLoading = true
            costumerModel.create(model).then(resp => {
                this.isLoading = false
                this.resetForm()
                this.$emit('submit', resp)
                this.hideModal()
                // this.$store.dispatch('notification/success', 'Data buy back berhasil disimpan.')
            }).catch(error => {
                this.isLoading = false
                this.$store.dispatch('notification/error', error)
            })
        }).catch(() => {})
      })
    }
  }
}
</script>
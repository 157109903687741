import BaseModel from '@/models/BaseModel.js'

export default class SaleDetail extends BaseModel {
    fields =  {
      'stock_id': {
        label: 'stock_id',
        type: 'hidden',
        rules: null
      },
      'stock_barcode': {
        label: 'barcode',
        type: 'hidden',
        rules: null
      },
      'nama': {
        label: 'Nama',
        type: 'text',
        rules: 'required'
      },
      'berat': {
        skipPriceTag: true,
        label: 'Berat (gram)',
        type: 'price',
        rules: 'required'
      },
      'kadar': {
        type: 'text',
        rules: 'required'
      },
      'foto': {
        type: 'image',
        rules: null
      },
      // 'harga_modal': {
      //   type: 'price',
      //   rules: null
      // },
      'harga_jual': {
        type: 'price',
        editable: true,
        rules: null
      },
    }
    endpoint = process.env.VUE_APP_API_URL + 'sales'
}
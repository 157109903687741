import axios from 'axios'
import BaseModel from '@/models/BaseModel.js'

export default class Stock extends BaseModel {
  fields =  {
    'nama': {
      label: 'Nama',
      type: 'text',
      rules: 'required'
    },
    'berat': {
      skipPriceTag: true,
      label: 'Berat (gram)',
      type: 'price',
      rules: 'required'
    },
    'kadar': {
      type: 'text',
      rules: 'required'
    },
    'sisa_stok': {
      type: 'number',
      rules: 'required'
    },
    'foto': {
      type: 'image',
      rules: null
    },
    'harga_modal': {
      type: 'price',
      rules: null
    },
    'harga_jual': {
      type: 'price',
      rules: null
    },
  }
  endpoint = process.env.VUE_APP_API_URL + 'stocks'

  findByBarcode (params = {}) {
    return new Promise((resolve, reject) => {
      const url = new URL(this.endpoint)
      const urlParams = new URLSearchParams(url.search)
      Object.keys(params).forEach(key => {
        if (typeof params[key] === 'string' || typeof params[key] === 'number') {
          urlParams.append(key, params[key])
        }
      })
      axios.get(this.endpoint + (Object.keys(params).length ? '?' + urlParams.toString() : '')).then(response => {
        return resolve(
          response.data.data ? response.data.data[0] : null
        )
      }).catch(error => {
        reject(error)
      })
    })
  }
}
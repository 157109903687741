<template>
    <div>
    <back-button default-to="/stocks"/>
      <b-card no-header no-footer>
        <ValidationObserver ref="form">
          <form @submit.prevent="onSubmit">
              <b-row>
                <b-col cols="12" md="6">
                  <template v-for="(m, index) in stockModel">
                    <div :key="index">
                      <form-group-input :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
                    </div>
                  </template>
                </b-col>
              </b-row>
              <div>
                <b-button type="submit" variant="primary" class="mt-0"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Simpan</b-button>
                <b-button :to="'/stocks'" variant="secondary" class="ml-3 float-right"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Batal</b-button>
              </div>
            </form>
        </ValidationObserver>
    </b-card>
  </div>
</template>

<script>
import { saveProcess, setModelValue, scrollToError, objectToOptions, classicObjectToOptions } from '@/_helpers'
import formGroupInput from '@/components/FormGroupInput.vue'
import Stock from '@/models/Stock.js'
import Compressor from 'compressorjs'

const stockModel = new Stock()

export default {
  components: {
    formGroupInput
  },
  watch: {
    '$route.params.id': function (val) {
      if (this.mode === 'update') {
        this.getDetail()
      }
    },
  },
  computed: {
    mode: function () {
      return this.$route.params.id !== undefined ? 'update' : 'create'
    }
  },
  data () {
    return {
      initEdit: false,
      isLoading: false,
      stockModel: stockModel.init()
    }
  },
  mounted () {
    if (this.mode === 'update') {
      this.getDetail()
    } else {
      this.stockModel.sisa_stok.value = 1
    }
  },
  methods: {
    getOptions (key) {
      return typeof this.stockModel[key].options !== 'undefined' ? objectToOptions(this.stockModel[key].options) : null
    },
    getClassicOptions (key) {
      return typeof this.stockModel[key].options !== 'undefined' ? classicObjectToOptions(this.stockModel[key].options) : null
    },
    async getDetail () {
      this.isLoading = true
      try {
        const resp = await stockModel.find(this.$route.params.id);
        setModelValue(this.stockModel, resp, true)
        Object.keys(this.stockModel).forEach(key => {
          if (typeof this.$refs[key] !== 'undefined') {
            this.$refs[key][0].setValue(this.stockModel[key].value)
          }
        })
      }
      catch (e) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(e)
        }
      }
      this.isLoading = false
    },
    resetForm () {
      this.$nextTick(() => {
        // clearModelValue(this.stockModel)
        Object.keys(this.stockModel).forEach(key => {
          this.$refs[key][0].setValue(null)
        })
        this.$refs.form.reset()
      })
    },
    async compress(image) {
      return new Promise((resolve, reject) => {
        new Compressor(image, {
          maxWidth: 600,
          maxHeight: 600,
          quality: 0.50,
          convertTypes: 'image/jpeg',
          success: resolve,
          error: reject,
        });
      })
    },
    onSubmit () {
      this.$refs.form.validate().then(async success => {
        if (!success || this.isLoading) {
          scrollToError(this.$refs.form)
          return false
        }
        if (this.stockModel.foto.value && this.stockModel.foto.value instanceof File) {
          const myBlob = await this.compress(this.stockModel.foto.value);
          this.stockModel.foto.value = new File([myBlob], this.stockModel.foto.value.name.replace(/\.[^/.]+$/, "") + '.jpeg');
          // new Compressor(this.stockModel.foto.value, {
          //   quality: 0.75,
          //   success(result) {
          //     this.stockModel.foto.value = result;
          //   },
          //   error(err) {
          //     console.log(err.message);
          //   },
          // });
        }
        // console.log(this.stockModel.foto.value.name)
        // console.log(this.stockModel);
        // return 1;
        saveProcess(this.stockModel, this.mode === 'update').then(model => {
          this.isLoading = true
          if (this.mode === 'update') {
            stockModel.update(this.$route.params.id, model).then(() => {
              this.isLoading = false
              this.$store.dispatch('notification/success', 'Data stock berhasil diubah.')
              this.$router.push('/stocks/' + this.$route.params.id)
            }).catch(error => {
              this.isLoading = false
              this.$store.dispatch('notification/error', error)
            })
          } else {
            stockModel.create(model).then(resp => {
              this.isLoading = false
              // this.resetForm()
              this.$store.dispatch('notification/success', 'Data stock berhasil disimpan.')
              this.$router.push('/stocks')
              // this.$router.push('/stocks/' + resp.id)
            }).catch(error => {
              this.isLoading = false
              this.$store.dispatch('notification/error', error)
            })
          }
        }).catch(() => {})
      })
    }
  }
}
</script>
import BaseModel from '@/models/BaseModel.js'

export default class BuyBackDetail extends BaseModel {
    fields =  {
      'nama': {
        label: 'Nama',
        type: 'text',
        rules: 'required'
      },
      'berat': {
        skipPriceTag: true,
        label: 'Berat (gram)',
        type: 'price',
        rules: 'required'
      },
      'kadar': {
        type: 'text',
        rules: 'required'
      },
      'harga_modal': {
        label: 'Harga',
        type: 'price',
        rules: null
      },
      'foto': {
        label: 'Foto',
        type: 'image',
        rules: null
      },
    }
    endpoint = process.env.VUE_APP_API_URL + 'buy-backs'
}
<template>
  <div>
    <back-button default-to="/crafts"/>
    <b-card no-header>
      <div class="d-flex mb-2">
        <b-button-group class="add-button-group ml-auto">
          <b-btn variant="primary" :href="`${apiUrl.craft}/${$route.params.id}?print_pdf=1&access_token=${$store.getters['account/accessToken']}`" target="_blank" type="button" ><font-awesome-icon icon="file-pdf"/> Cetak Invoice</b-btn>
        </b-button-group>
      </div>
      <b-row>
        <b-col cols="12" md="6" class="mb-1">
          <div>
            <div>
              <label for="nama" class="form-label mb-2">
                Pelanggan
              </label>
            </div>
            <span v-if="costumer" style="cursor: pointer;" @click.prevent="$refs.costumerDetailModal.openModal(costumer.id)">
              <div class="d-flex flex-wrap" style="cursor: pointer;">
                <div class="mb-2 mr-2" style="cursor: pointer;"><strong>Nama</strong>: {{ costumer.nama}}</div><div class="mb-2 mr-2">, <strong>No. HP</strong>: {{ costumer.no_hp }}</div><div class="mb-2" v-if="costumer.nik">, <strong>NIK</strong>: {{ costumer.nik }}</div>
              </div>
            </span>
          </div>
        </b-col>
        <div class="col-md-6 col-12"><div><div class="mb-3"><label class="form-label w-100" style="font-size: 80%;">No. Invoice</label><p class="heading-color">{{ transaction_no }}</p></div></div></div>
        <b-col cols="12" md="3" v-for="(m, index) in filterCraftModel(['tanggal_transaksi', 'tanggal_diambil'])"  :key="'craft_model_1_ ' + index">
          <div>
            <detail-group-input :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :need-label="true" v-model="m.value" :id="index" :skipPriceTag="index === 'berat'" />
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div><div class="mb-3"><label class="form-label w-100" style="font-size: 80%;">Status</label><p class="heading-color">
            <b-badge variant="success" class="p-2" v-if="model.status_bayar.value === 'lunas'">Lunas</b-badge>
            <b-badge variant="secondary" class="p-2" v-else>Belum Lunas</b-badge>
          </p></div></div>
        </b-col>
      </b-row>
      <div class="mt-2 mb-3">
        <form-detail ref="formDetail" :model="detailModel" mode="detail" :rows="details" colTotlalName="harga" />
      </div>
      <div class="mt-2 mb-3">
        <b-row>
          <b-col cols="12" md="2" v-for="(m, index) in filterCraftModel(['panjar', 'sisa', 'metode_pembayaran'])"  :key="'craft_model_2_ ' + index">
            <div>
              <detail-group-input :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :need-label="true" v-model="m.value" :id="index" :skipPriceTag="index === 'berat'" />
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="mt-2 mb-3">
        <b-row>
          <b-col cols="12" md="12" v-for="(m, index) in filterCraftModel(['catatan'])"  :key="'craft_model_3_ ' + index">
            <div>
              <detail-group-input :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :need-label="true" v-model="m.value" :id="index" :skipPriceTag="index === 'berat'" />
            </div>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-button v-if="$store.getters['account/accountData'].role === Roles.ADMIN" :to="'/crafts/' + $route.params.id + '/edit'" variant="primary" class="mt-0">Ubah</b-button>
        <b-button :to="'/crafts/'" variant="secondary" class="ml-3 float-right">Kembali</b-button>
      </div>
    </b-card>
    <costumer-detail-modal ref="costumerDetailModal" title="Detail Data Pelanggan"/>
  </div>
</template>

<script>
import { setModelValue, Roles } from '@/_helpers'
import detailGroupInput from '@/components/DetailGroupInput.vue'
import costumerDetailModal from '@/pages/Costumer/DetailModal.vue'
import Craft from '@/models/Craft.js'
import formDetail from '@/components/FormDetail';
import CraftDetail from '@/models/CraftDetail.js'

const craftModel = new Craft()
const craftDetailModel = new CraftDetail()

export default {
  components: {
    detailGroupInput,
    costumerDetailModal,
    formDetail
  },
  watch: {
    '$route.params.id': function (val) {
      if (val !== undefined) {
        this.getDetail()
      }
    }
  },
  data () {
    return {
      Roles: Roles,
      isLoading: false,
      transaction_no: null,
      costumer: null,
      details: [],
      model: craftModel.init(),
      detailModel: craftDetailModel.init(),
      apiUrl: {
        craft: craftModel.endpoint
      },
    }
  },
  mounted () {
    this.getDetail()
  },
  methods: {
    filterCraftModel (search, type = 'include') {
      const filtered = Object.entries(this.model).filter(mdl => {
        return type === 'include' ? search.includes(mdl[0]) : !search.includes(mdl[0])
      });
      let result = {}
      filtered.forEach(element => {
        result[element[0]] = element[1];
      });
      return result;
    },
    filterModel (search) {
      const filtered = Object.entries(this.model).filter(mdl => {
        return search.includes(mdl[0])
      });
      let result = {}
      filtered.forEach(element => {
        result[element[0]] = element[1];
      });
      return result;
    },
    async getDetail () {
      this.isLoading = true
      try {
        const resp = await craftModel.find(this.$route.params.id);
        this.costumer = resp.costumer;
        this.details = resp.detail_barang;
        this.transaction_no = resp.transaction_no;
        setModelValue(this.model, resp);
      }
      catch (e) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(e)
        }
      }

      this.isLoading = false
    }
  }
}
</script>
<template>
  <b-modal id="add-modal" :title="title" hide-footer>
    <ValidationObserver ref="form">
      <form @submit.prevent="onSubmit">
        <template v-for="(m, index) in model">
          <div :key="'add_modal_' + index">
            <form-group-input :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
          </div>
        </template>
        <div>
            <b-button type="submit" variant="primary" class="mt-0"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Simpan</b-button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { saveProcess, objectToOptions, classicObjectToOptions, setModelValue } from '@/_helpers'
import formGroupInput from '@/components/FormGroupInput.vue'

export default {
  components: {
    formGroupInput
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Tambah Data Barang'
    },
    // fotoRequired: {
    //   type: Boolean,
    //   required: false,
    //   default: false
    // },
    model: {
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      fotoRequired: true
    }
  },
  methods: {
    setFotoRequired (value) {
      this.fotoRequired = value
    },
    openModal () {
      this.$bvModal.show('add-modal')
      setTimeout(() => {
        this.resetForm()
        document.querySelector('#add-modal #' + Object.keys(this.model)[0]).focus()
      }, 200);
      setTimeout(() => {
        if (this.fotoRequired) {
          if (this.model.foto) {
            this.model.foto.rules = 'required'
          }
        } else {
          if (this.model.foto) {
            this.model.foto.rules = null
          }
        }
      }, 700);
    },
    hideModal () {
      this.$bvModal.hide('add-modal')	
    },
    getOptions (key) {
      return typeof this.model[key].options !== 'undefined' ? objectToOptions(this.model[key].options) : null
    },
    getClassicOptions (key) {
      return typeof this.model[key].options !== 'undefined' ? classicObjectToOptions(this.model[key].options) : null
    },
    resetForm () {
      setModelValue(this.model, {}, true)
      Object.keys(this.model).forEach(key => {
        if (typeof this.$refs[key] !== 'undefined' && key !== 'gender') {
          this.$refs[key][0].setValue(null)
        }
      })
    },
    async onSubmit () {
      this.$refs.form.validate().then(success => {
        if (!success || this.isLoading) {
          return false
        }
        saveProcess(this.model, false, true).then(model => {
          this.$emit('submit', model)
          this.hideModal()
        }).catch(() => {})
      })
    }
  }
}
</script>
<template>
  <div>
    <back-button default-to="/users"/>
    <b-card no-header>
      <b-row>
        <b-col cols="12" md="6">
          <template v-for="(m, index) in model">
            <div :key="index">
              <detail-group-input :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :need-label="true" v-model="m.value" :id="index" />
            </div>            
          </template>
        </b-col>
      </b-row>
      <div>
        <b-button :to="'/users/' + $route.params.id + '/edit'" variant="primary" class="mt-0">Ubah</b-button>
        <b-button :to="'/users/'" variant="secondary" class="ml-3 float-right">Kembali</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { setModelValue } from '@/_helpers'
import detailGroupInput from '@/components/DetailGroupInput.vue'
import User from '@/models/User.js'

const userModel = new User()

export default {
  components: {
    detailGroupInput
  },
  watch: {
    '$route.params.id': function (val) {
      if (val !== undefined) {
        this.getDetail()
      }
    }
  },
  data () {
    return {
      isLoading: false,
      model: userModel.init()
    }
  },
  mounted () {
    this.model.password.type = 'hidden'
    this.getDetail()
    // alert(window.history.length)
  },
  methods: {
    filterModel (search) {
      const filtered = Object.entries(this.model).filter(mdl => {
        return search.includes(mdl[0])
      });
      let result = {}
      filtered.forEach(element => {
        result[element[0]] = element[1];
      });
      return result;
    },
    getDetail () {
      this.isLoading = true
      userModel.find(this.$route.params.id).then(resp => {
        setModelValue(this.model, resp)
      }).catch(error => {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error)
        }
        this.isLoading = false
      })
    }
  }
}
</script>
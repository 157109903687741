<template>
  <div>
    <back-button default-to="/stocks"/>
    <b-card no-header>
      <b-row>
        <b-col cols="12" md="6">
          <div class="d-flex">
            <div class="mb-3"><label class="form-label w-100" style="font-size: 80%;">Barcode</label>
              <p class="heading-color">{{barcode}} <a v-if="barcode" href="#" @click.prevent="copyBarcode" style="border-radius: 4px;" class="bg-secondary px-2 py-1 text-light"><font-awesome-icon icon="clipboard"/></a></p>
            </div>
            <input type="text" id="barcode-text" v-model="barcode" style="position: absolute; z-index:-1111;">
          </div>          
          <template v-for="(m, index) in model">
            <div :key="index">
              <detail-group-input :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :need-label="true" v-model="m.value" :id="index" :skipPriceTag="index === 'berat'" />
            </div>            
          </template>
        </b-col>
      </b-row>
      <div>
        <b-button :to="'/stocks/' + $route.params.id + '/edit'" variant="primary" class="mt-0">Ubah</b-button>
        <b-button :to="'/stocks/'" variant="secondary" class="ml-3 float-right">Kembali</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { setModelValue } from '@/_helpers'
import detailGroupInput from '@/components/DetailGroupInput.vue'
import Stock from '@/models/Stock.js'

const stockModel = new Stock()

export default {
  components: {
    detailGroupInput
  },
  watch: {
    '$route.params.id': function (val) {
      if (val !== undefined) {
        this.getDetail()
      }
    }
  },
  data () {
    return {
      isLoading: false,
      barcode: null,
      model: stockModel.init()
    }
  },
  mounted () {
    this.getDetail()
  },
  methods: {
    copyBarcode () {
      let copyText = document.getElementById("barcode-text");
      // Select the text field
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.value);
      this.$store.dispatch('notification/success', 'Barcode berhasil di copy.')
    },
    filterModel (search) {
      const filtered = Object.entries(this.model).filter(mdl => {
        return search.includes(mdl[0])
      });
      let result = {}
      filtered.forEach(element => {
        result[element[0]] = element[1];
      });
      return result;
    },
    async getDetail () {
      this.isLoading = true
      try {
        const resp = await stockModel.find(this.$route.params.id);
        this.barcode = resp.stock_barcode
        setModelValue(this.model, resp);
      }
      catch (e) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(e)
        }
      }

      this.isLoading = false
    }
  }
}
</script>
import axios from 'axios'
import BaseModel from '@/models/BaseModel.js'

export default class Costumer extends BaseModel {
    fields =  {
      'gender': {
        type: 'radio',
        label: 'Jenis Kelamin',
        rules: 'required',
        options: {
          'wanita': 'Wanita',
          'pria': 'Pria',
        }
      },
      'nama': {
        label: 'Nama',
        type: 'text',
        rules: 'required'
      },
      'no_hp': {
        label: 'No. HP',
        type: 'text',
        rules: 'required'
      },
      'no_hp_lainnya': {
        label: 'No. HP Lainnya',
        type: 'text',
        rules: null
      },
      'nik': {
        label: 'NIK',
        type: 'number',
        rules: null
        // rules: 'min:16|max:16'
      },
      'alamat': {
        type: 'textarea',
        rules: null
      },
      'email': {
        type: 'email',
        rules: null
      },
      'alamat': {
        type: 'textarea',
        rules: null
      },
      'alamat_domisili': {
        type: 'textarea',
        rules: null
      },
      'tanggal_lahir': {
        label: 'Tanggal Lahir',
        type: 'date',
        rules: null
      },
      'status_perkawinan': {
        label: 'Status',
        type: 'enum',
        options: {
          'belum-kawin': 'Belum Kawin',
          'kawin': 'Kawin',
          'cerai-hidup': 'Cerai Hidup',
          'cerai-mati': 'Cerai Mati',
        },
      },
    }
    endpoint = process.env.VUE_APP_API_URL + 'costumers'

    findByQuery (q) {
      return new Promise((resolve, reject) => {
        axios.get(this.endpoint + '?q=' + encodeURI(q)).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
}